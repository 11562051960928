<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <h2 class="font-semibold">Company Info</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form
          @submit.prevent="updateCompanyInfo"
          class="card pt-2 form-control"
        >
          <label class="label"
            ><span class="label-text text-black">Email</span></label
          >
          <input
            v-model="companyEmail"
            type="email"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black">Facebook Link</span></label
          >
          <input
            v-model="companyFacebook"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black">Instagram Link</span></label
          >
          <input
            v-model="companyInstagram"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black"
              >YouTube Channel Link</span
            ></label
          >
          <input
            v-model="companyYoutube"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black"
              >Whatsapp Contact Number</span
            ></label
          >
          <input
            v-model="companyWhatsappContactNumber"
            type="text"
            class="input input-md input-bordered w-full"
          />

          <label class="label"
            ><span class="label-text text-black">Operating Hour</span></label
          >
          <ckeditor
            v-model="companyOperatingHour"
            :config="editorConfig"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../components/SideNavBar.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "ManageCompanyInfo",
  components: { SideNavBar },
  data() {
    return {
      isLoading: true,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "undo",
          "redo",
        ],
      },
      companyEmail: "",
      companyFacebook: "",
      companyInstagram: "",
      companyYoutube: "",
      companyWhatsappContactNumber: "",
      companyOperatingHour: "",
    };
  },
  mounted() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo() {
      this.axios({
        url: "/company/getCompanyInfo",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.companyEmail = response.data.companyEmail;
          this.companyFacebook = response.data.companyFacebook;
          this.companyInstagram = response.data.companyInstagram;
          this.companyYoutube = response.data.companyYoutube;
          this.companyWhatsappContactNumber =
            response.data.companyWhatsappContactNumber;
          this.companyOperatingHour =
            response.data.companyOperatingHour == null
              ? ""
              : response.data.companyOperatingHour;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    updateCompanyInfo() {
      this.axios({
        url: "/company/setCompanyInfo",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          companyEmail: this.companyEmail,
          companyFacebook: this.companyFacebook,
          companyInstagram: this.companyInstagram,
          companyYoutube: this.companyYoutube,
          companyWhatsappContactNumber: this.companyWhatsappContactNumber,
          companyOperatingHour: this.companyOperatingHour,
        },
      })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.getCompanyInfo();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
  },
};
</script>

<style>
@import "../assets/CustomCKEditorStyle.css";
</style>